import { Link } from 'gatsby';
import React from 'react';
import { getButtonClass } from './shared';
import { ILinkButtonProps } from './types';

export const LinkButton: React.FC<ILinkButtonProps> = ({ children, color, dimension, secondary, className, href }) => {
  const resultingClasses = getButtonClass(color, dimension, secondary, className);

  if (href.includes('https://') || href.includes('http://')) {
    return (
      <a href={href} className={resultingClasses}>
        {children}
      </a>
    );
  }

  return (
    <Link to={href} className={resultingClasses}>
      {children}
    </Link>
  );
};
